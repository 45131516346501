import React, { useEffect, useState } from 'react';
import useFirebase from 'vendor/Firebase';
import styled from 'styled-components';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { useLocation } from 'react-router-dom';
import {
  newBodyAtom,
  bodyDataAtom,
  hideUnselectedOptionsAtom,
  neckDataAtom,
  newNeckAtom,
  currentPartAtom,
  partEditModeAtom,
  customerPartsAtom,
} from 'shared/state/pricingState';
import { nextCustomerPart, updateBodyPricing, updateNeckPricing } from 'shared/data';
import { find, omit } from 'lodash';
import {
  NewItemHeader,
} from './Components';
import { IBomItem, ICustomerRecord } from '../../shared/types/dbRecords';
import PartDetailColumn from './Components/PartDetailColumn';
import PartPricingColumn from './Components/PartPricingColumn';
import { FlexColumn, FlexRow } from '../../shared/containers/FlexContainer';
import DensityCalculatorDrawer from '../Util/DensityCalculator/Components/DensityCalculatorDrawer';
import { PART_BOM_COLLECTION, PART_VIEWER_COLLECTION, partBomItemsAtom } from '../../shared/state/partViewState';
import { IPartBom } from '../../shared/types/pricingTool';
import InventoryQuickEditDrawer from '../Inventory/Components/InventoryQuickEditDrawer';
import { currentCustomerAtom, customersAtom } from '../../shared/state/customerState';

const qs = require('qs');

const ComponentWrapper = styled(FlexColumn)`
  width: calc(100% - 100px);
  height: 100vh;
  align-items: flex-start;
  justify-content: flex-start;
  position: relative;
  left: 68px;
`;

const PartDataRow = styled(FlexRow)`
  width: 100%;
  min-height: 50vh;
  flex-grow: 2;
  align-items: flex-start;
`;

export default () => {
  const location = useLocation();
  const {
    customer, partId, copy,
  } = qs.parse(location.search.replace('?', ''));
  const partType = window.location.href.match(/body/i) ? 'body' : 'neck';
  const { firestore } = useFirebase();

  const customers = useRecoilValue(customersAtom);
  const setCurrentCustomer = useSetRecoilState(currentCustomerAtom);
  const setHidden = useSetRecoilState(hideUnselectedOptionsAtom);
  const newPartAtom = partType === 'body' ? newBodyAtom : newNeckAtom;

  const setPartEditMode = useSetRecoilState(partEditModeAtom);

  // @ts-ignore
  const [newPartState, setNewPartState] = useRecoilState(newPartAtom);
  const setCurrentPart = useSetRecoilState(currentPartAtom);
  const dataAtom = partType === 'body' ? bodyDataAtom : neckDataAtom;
  const partData = useRecoilValue(dataAtom);
  const partViewerDataString = useRecoilValue(PART_VIEWER_COLLECTION);
  const partBomDbString = useRecoilValue(PART_BOM_COLLECTION);
  const setBomItems = useSetRecoilState(partBomItemsAtom);

  const [_siblings, setSiblings] = useState<any[]>([]);
  const [customerParts, setCustomerParts] = useState<any[]>([]);

  const pricingUpdateMethod = partType === 'body' ? updateBodyPricing : updateNeckPricing;

  const fetchParent = async (childSku: string|null) => {
    let parent = null;
    if (childSku) {
      const parentSku = childSku.split('.')[0];
      const parentDoc = await firestore.collection(partViewerDataString).doc(parentSku).get();
      parent = parentDoc.exists ? parentDoc.data() : null;
    }
    return parent;
  };

  useEffect(() => {
    setHidden(!!partId);

    if (!copy && partId) {
      // This is the case where a part exists—we have a partId and the copy flag is false
      // In this case, we are viewing/editing an existing part.
      firestore.collection(partViewerDataString).doc(partId)
        .get()
        .then((doc) => {
          if (!doc.exists) throw new Error('No record for that id');
          const data = doc.data();
          if (!data) throw new Error('No data for that record');

          fetchParent(data.childSku || null, data.customer, data.type).then((res) => {
            const parent = res;
            // If the part doesn't have pricing, we will not worry about ensuring the pricing is up-to-date.
            if (!data.pricing) {
              const customerRecord = find(customers, (c: ICustomerRecord) => c.id === partId.slice(0, 5));
              if (customerRecord) {
                setCurrentCustomer(customerRecord);
                const newState = {
                  ...newPartState,
                  ...data,
                  customerId: customerRecord.id,
                  description: data.description,
                  partNumber: partId,
                  id: partId,
                  qboId: data.qboId,
                  notes: data.notes,
                  childSku: data.childSku || null,
                  parent,
                };
                // @ts-ignore
                if (partType === 'body') newState.volume = parent?.volume || data.volume;
                setNewPartState(newState);
              }
            } else {
            // If the part does have pricing, we need to compare it to the current pricing in our database
            // to make sure that all components of this part price are up-to-date.
            // The records are merged into one, with the updated pricing integrated.
              const recordData = pricingUpdateMethod(data.pricing, partData);
              const newState = {
                ...data,
                ...recordData,
                id: partId,
                active: data.active,
                Sku: data.Sku,
                Description: data.Description,
                qboId: data.Id,
                notes: data.notes,
                childSku: data.childSku || null,
                parent,
              };

              if (partType === 'body') {
                newState.volume = parent?.volume || data.volume;
              }
              setNewPartState(newState);
              setCurrentPart(newState);

              // @ts-ignore
              setCurrentCustomer(find(customers, (c: ICustomerRecord) => c.id === data.customer));
            // firestore.collection(customersDbString).doc(data.customer).get().then((d) => {
            //   setCurrentCustomer(d.data() as ICustomerRecord);
            // });
            }
          });
        });
    } else if (copy) {
      const tempData = localStorage.getItem('pricing.temp.part');
      if (!tempData) throw new Error('no copy data to read!');
      const copyRecord = JSON.parse(tempData);
      firestore.collection(partViewerDataString)
        .where('customer', '==', customer)
        .where('type', '==', copyRecord.type)
        .get()
        .then((customerPartDocs) => {
          const parts = customerPartDocs.docs.map((d) => d.data());
          setCustomerParts(parts);
          const parentSku = copyRecord.childSku ? copyRecord.childSku.split('.')[0] : copyRecord.oldPartNumber;
          const parent = parts.find((p) => p.Sku === parentSku);
          const matchType = partType === 'body' ? 'B' : 'N';
          const nextPart = nextCustomerPart(customer, parts, `[G|B]${matchType}`);
          const childSku = parent ? `${parent.Sku}.${nextPart.split('_')[1]}` : null;
          const recordData = pricingUpdateMethod(copyRecord.pricing || {}, partData);
          setNewPartState({
            ...newPartState,
            ...recordData,
            ...omit(copyRecord, 'oldPartNumber'),
            parent,
            childSku,
          });
          // @ts-ignore
          setCurrentCustomer(find(customers, (c: ICustomerRecord) => c.id === copyRecord.customer));
          firestore.collection(partBomDbString).doc(copyRecord.oldPartNumber).get().then((doc) => {
            if (!doc.exists) return;
            const bomData = doc.data() as IPartBom;
            setBomItems(bomData.bom);
            setPartEditMode(true);
          });
        });
    } else if (!copy && !partId && customer) {
      firestore.collection(partViewerDataString).where('customer', '==', customer).get().then((snap) => {
        const _customerParts: any[] = snap.docs.map((d) => d.data());
        const matchType = partType === 'body' ? 'B' : 'N';
        const nextPart = nextCustomerPart(customer, _customerParts, `[G|B]${matchType}`);
        const customerRecord = find(customers, (c: ICustomerRecord) => c.id === customer);
        if (customerRecord) {
          setCurrentCustomer(customerRecord);
          setNewPartState({
            ...newPartState,
            id: nextPart,
            Sku: nextPart,
            customerId: customerRecord.id,
          });
        }
        setPartEditMode(true);
      });
    }
  }, [partId]);

  return (
    <>
      <InventoryQuickEditDrawer />
      <DensityCalculatorDrawer key="density-calculator-drawer" />
      <ComponentWrapper key="part-record-component-wrapper">
        {/* <BomEditor partNumber={partId} /> */}
        <NewItemHeader partType={partType} partId={partId || newPartState.id} copy={copy} partState={newPartState} />
        <PartDataRow key="part-record-part-data-row">
          <PartDetailColumn key="part-record-part-detail-column" partType={partType} partId={partId || newPartState.id} copy={copy} partState={newPartState} siblings={_siblings} customerParts={customerParts || []} />
          <PartPricingColumn key="part-record-part-pricing-column" partType={partType} />
        </PartDataRow>
      </ComponentWrapper>
    </>
  );
};
